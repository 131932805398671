import { OUTPUT_MODE, ODDS_MARKET_TYPE } from "../../utils/enums";

/**
 * -----------------------------------------------
 * STATE
 * -----------------------------------------------
 */
const defaultState = {
	/**
	 * The event include's output mode.
	 */
	outputMode: OUTPUT_MODE.SINGLE_MARKET,

	/**
	 * The selected market type.
	 */
	oddsMarketType: ODDS_MARKET_TYPE.EVENTS
};

/**
 * -----------------------------------------------
 * MUTATIONS
 * -----------------------------------------------
 */
const mutations = {
	/**
	 * Update the "outputMode" state variable.
	 * @param {Object} state The Store State
	 * @param {String} mode The selected mode
	 */
	OUTPUT_MODE_UPDATED: (state, mode) => {
		state.outputMode = mode;
	},

	/**
	 * Update the "oddsMarketType" state variable.
	 * @param {Object} state The Store State
	 * @param {String} mode The selected mode
	 */
	ODDS_MARKET_TYPE_UPDATED: (state, type) => {
		state.oddsMarketType = type;
	}
};

/**
 * -----------------------------------------------
 * GETTERS
 * -----------------------------------------------
 */
const getters = {
	/**
	 * Get the "outputMode" state variable.
	 * @returns {String} the default/selected output mode.
	 */
	outputMode: state => state.outputMode,

	/**
	 * Get the "oddsMarketType" state variable.
	 * @returns {String} the default/selected market type.
	 */
	oddsMarketType: state => state.oddsMarketType
};

export default {
	state: defaultState,
	getters,
	mutations,
};
