<template>
	<div class="box">
		<div class="title is-5">{{ data.name }}</div>

		<template v-if="outputMode === OUTPUT_MODE.MULTIPLE_MARKETS">
			<div v-for="(market, index) of data.markets" :key="index" class="columns">
				<div class="column">
					<b>Market:</b>
					{{ market.market }}
				</div>
				<div class="column">
					<b>Outcome:</b>
					{{ market.outcome }}
				</div>
			</div>
		</template>

		<div v-else class="columns">
			<div class="column">
				<b>Market:</b>
				{{ data.market }}
			</div>
			<div class="column">
				<b>Outcome:</b>
				{{ data.outcome }}
			</div>
		</div>

		<div class="buttons is-centered">
			<button
				class="button is-primary"
				@click="
					MODIFY_EVENT({
						id: data.event_id,
						market: data.market,
						action: EVENT_ACTIONS.MOVE_UP,
					})
				"
				:disabled="isFirstItem"
			>
				Move Up
			</button>

			<button
				class="button is-info"
				@click="
					MODIFY_EVENT({
						id: data.event_id,
						market: data.market,
						action: EVENT_ACTIONS.MOVE_DOWN,
					})
				"
				:disabled="isLastItem"
			>
				Move Down
			</button>

			<button
				class="button is-danger"
				@click="
					MODIFY_EVENT({
						id: data.event_id,
						market: data.market,
						action: EVENT_ACTIONS.REMOVE,
					})
				"
			>
				Remove
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import { EVENT_ACTIONS, OUTPUT_MODE } from "../../utils/enums";

export default {
	data: () => ({
		EVENT_ACTIONS,
		OUTPUT_MODE,
	}),

	props: {
		data: Object,
	},

	computed: {
		...mapGetters(["selectedSportEvents", "outputMode"]),

		isFirstItem() {
			return this.selectedSportEvents.findIndex((i) => i.id === this.data.id && i.market === this.data.market) === 0 ? true : false;
		},

		isLastItem() {
			return this.selectedSportEvents.findIndex((i) => i.id === this.data.id && i.market === this.data.market) === this.selectedSportEvents.length - 1 ? true : false;
		},
	},

	methods: {
		...mapMutations(["MODIFY_EVENT"]),
	},
};
</script>
