import { TOURNAMENTS, SPORTS } from "./utils/enums";

/**
 * The filter object used on the "getSports" query
 * to get only the most important ones.
 */
export const SPORTS_LIST_QUERY_FILTER = [
	SPORTS.BASKETBALL,
	SPORTS.AMERICAN_FOOTBALL,
	SPORTS.ICE_HOCKEY,
	SPORTS.BASEBALL,
	// SPORTS.GOLF,
	SPORTS.MMA,
	// SPORTS.BOXING,
	SPORTS.SOCCER,
];

/**
 * The filter object used on the "getSports" query
 * to get only the most important ones.
 */
export const SPORTS_LIST_QUERY_FILTER_OUTRIGHTS = [
	SPORTS.BASKETBALL,
	SPORTS.AMERICAN_FOOTBALL,
	SPORTS.ICE_HOCKEY,
	SPORTS.BASEBALL,
	SPORTS.GOLF,
	// SPORTS.TENNIS,
	SPORTS.MOTORSPORT,
	SPORTS.SOCCER,
];

/**
 * The Amplify's configuration.
 */
export const MATRIX_CORE_CONFIG = {
	endpoint: "https://core.kaxmatrix.com",
};

/**
 * The Twig Include's name.
 */
export const INCLUDE_NAME = "BDC - View -- Pick - Table";

export function competitorsOrder(tournamentName) {
	switch (tournamentName) {
		case TOURNAMENTS.NFL:
		case TOURNAMENTS.XFL:
		case TOURNAMENTS.NCAAF:
			return ["away", "home"];

		default:
			return ["home", "away"];
	}
}
