import {
	getSports as getSportsModel,
	getTournamentsBySport as getTournamentsBySportModel,
	getSportEventsByTournament as getSportEventsByTournamentModel
} from "../models/sport-events";

import { SPORTS_LIST_QUERY_FILTER, SPORTS_LIST_QUERY_FILTER_OUTRIGHTS } from "../../config";
import { TOURNAMENTS, ODDS_MARKET_TYPE, SPORTS } from "../../utils/enums";

/**
 * Controller for "getSports" query.
 * @param marketType
 */
export function getSports(marketType) {
	let promise;

	if (marketType === ODDS_MARKET_TYPE.OUTRIGHTS) {
		promise = getSportsModel({
			filter: {
				or: SPORTS_LIST_QUERY_FILTER_OUTRIGHTS.map(i => ({ id: { eq: i } }))
			}, limit: 100
		});
	} else {
		promise = getSportsModel({
			filter: {
				or: SPORTS_LIST_QUERY_FILTER.map(i => ({ id: { eq: i } }))
			}, limit: 100
		});
	}

	return promise.then(({ data }) => {
		return data.data;
	})
		.catch(console.error);
}

/**
 * Controller for "getTournamentsBySport" query.
 * @param {*} options 
 */
export function getTournamentsBySport(options) {
	return getTournamentsBySportModel(options)
		.then(({ data }) => {
			const tournaments = data.data;

			// Filter tournaments to get only tournaments from enum TODO: Config file from it
			return tournaments.filter(tournament => {
				// Don't filter out MMA tournaments.
				if (tournament.sport_id === SPORTS.MMA) return true;

				// Else
				return Object.values(TOURNAMENTS).find(tournamentEnumId => tournamentEnumId === tournament.id);
			})
		})
		.catch(console.error);
}

/**
 * Controller for "getSportEventsByTournament" query.
 * @param {*} options 
 */
export function getSportEventsByTournament(options) {
	return getSportEventsByTournamentModel(options)
		.then(({ data }) => {
			data = data.data.map(item => {
				// --------------------------------------
				// Save qualifiers as objects home/away.
				item.competitors.forEach(i => {
					item.competitors[i.qualifier] = i;
				});
				// --------------------------------------

				item.marketsList = [...new Set(item.markets.map(i => i.key))]; // Get all available markets for a tournament/event.

				// --------------------------------------
				// Get all outcome types for each market and store them as objects.
				item.outcomeTypes = {};
				item.marketsList.forEach(i => {
					let market = item.markets.filter(j => j.key === i)[0];
					let outcomesList = [];

					if (market) {
						market.books.forEach(y => {
							outcomesList.push(...y.outcomes.map(z => z.qualifier))
						});
						item.outcomeTypes[i] = [...new Set(outcomesList)];
					}
				});
				// --------------------------------------

				return item;
			});

			return data;
		})
		.catch(console.error);
}
