import moment from "moment-timezone";

import { getSportEventsByTournament } from "../../services/controllers/sport-events";
import { EVENT_ACTIONS } from "../../utils/enums";

/**
 * -----------------------------------------------
 * STATE
 * -----------------------------------------------
 */
const defaultState = {
	/** The available sport events for the selected tournament. */
	sportEvents: [],
	/** The selected sport events. */
	selectedSportEvents: []
};

/**
 * -----------------------------------------------
 * ACTIONS
 * -----------------------------------------------
 */
const actions = {
	/** Call the "getSportEventsByTournament" controller. */
	getSportEvents: async (context) => {
		const currentUTCDate = moment(new Date()).utc().clone();

		const options = {
			scheduledStart: currentUTCDate.format("YYYY-MM-DD HH:mm:ss"),
			scheduledEnd: currentUTCDate.endOf("day").add(6, "months").format("YYYY-MM-DD HH:mm:ss"),
			limit: 50
		};

		try {
			options.leagueId = context.rootState.tournaments.selectedTournament.id;

			context.commit("SPORT_EVENTS_UPDATED", await getSportEventsByTournament(options));
		} catch (error) {
			console.error(error);
		}
	},
};

/**
 * -----------------------------------------------
 * MUTATIONS
 * -----------------------------------------------
 */
const mutations = {
	/**
	 * Update the "sportEvents" state variable.
	 * @param {Object} state The Store State
	 * @param {Array<Object>} sportEvents the list with the sport events.
	 */
	SPORT_EVENTS_UPDATED: (state, sportEvents) => {
		state.sportEvents = sportEvents;
	},

	/**
	 * Update the "selectedSportEvents" state variable.
	 * @param {Object} state The Store State
	 * @param sportEvent
	 */
	SPORT_EVENT_SELECTED: (state, sportEvent) => {
		if (sportEvent.hasMultipleMarkets) {
			let listedEventIndex = state.selectedSportEvents.findIndex(i => i.id === sportEvent.id);

			if (listedEventIndex !== -1) {
				let listedEvent = state.selectedSportEvents[listedEventIndex];

				if (listedEvent.markets.length <= 2 && !listedEvent.markets.find(j => j.market === sportEvent.market)) {
					state.selectedSportEvents[listedEventIndex].markets.push({ market: sportEvent.market, outcome: sportEvent.outcome })
				}
			} else {
				state.selectedSportEvents.push({
					id: sportEvent.id,
					name: sportEvent.name,
					markets: [{ market: sportEvent.market, outcome: sportEvent.outcome }],
					home: sportEvent.home,
					away: sportEvent.away,
					tournamentId: sportEvent.tournamentId,
					tournamentName: sportEvent.tournamentName,
					hasMultipleMarkets: sportEvent.hasMultipleMarkets
				});
			}
		} else {
			state.selectedSportEvents.push(sportEvent);
		}
	},

	/**
	 * Clear the "selectedSportEvents" array.
	 * @param {Object} state The Store State
	 */
	CLEAR_SELECTED_EVENTS: (state) => {
		state.selectedSportEvents = [];
	},

	/**
	 * Remove/Move Up/Move Down each selected item in the "selectedSportEvents" variable.
	 * @param {Object} state The Store State
	 * @param {Object} options the item's id and action type.
	 */
	MODIFY_EVENT: (state, options) => {
		let itemIndex = state.selectedSportEvents.findIndex(i => i.id === options.id && i.market === options.market);
		let selectedEventsList = state.selectedSportEvents;

		switch (options.action) {
			case EVENT_ACTIONS.MOVE_UP:
				if (itemIndex > 0) {
					const el = selectedEventsList[itemIndex];
					selectedEventsList[itemIndex] = selectedEventsList[itemIndex - 1];
					selectedEventsList[itemIndex - 1] = el;
					state.selectedSportEvents = [...selectedEventsList];
				}
				break;
			case EVENT_ACTIONS.MOVE_DOWN:
				if (itemIndex !== -1 && itemIndex < selectedEventsList.length - 1) {
					const el = selectedEventsList[itemIndex];
					selectedEventsList[itemIndex] = selectedEventsList[itemIndex + 1];
					selectedEventsList[itemIndex + 1] = el;
					state.selectedSportEvents = [...selectedEventsList];
				}
				break;
			case EVENT_ACTIONS.REMOVE:
				state.selectedSportEvents.splice(itemIndex, 1);
				break;
		}
	},
};

/**
 * -----------------------------------------------
 * GETTERS
 * -----------------------------------------------
 */
const getters = {
	/**
	 * Get the "sportEvents" state variable.
	 * @returns {Array<Object>} the available sport events.
	 */
	sportEvents: state => state.sportEvents,
	/**
	 * Get the "selectedSportEvents" state variable.
	 * @returns {Array<Object>} the selected sport events.
	 */
	selectedSportEvents: state => state.selectedSportEvents
};

export default {
	state: defaultState,
	getters,
	actions,
	mutations,
};
