<template>
	<div class="include-snippet">
		<div class="heading has-text-weight-bold">Blade Include</div>
		<div class="control">
			<textarea class="textarea has-fixed-size" readonly :disabled="selectedOutrights.length === 0" v-model="generateInclude" style="cursor: initial"></textarea>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters(["selectedOutrights"]),

		generateInclude() {
			return `<livewire:matrix.outrights-table :outrights="${JSON.stringify(this.selectedOutrights, null, "\t").replace(/'/g, "\\'").replace(/"/gm, "'").replace(/{/gm, "[").replace(/}/gm, "]").replace(/:\s/gm, " => ")}" />`;
		},
	},
};
</script>
