<template>
	<div class="section">
		<div class="container">
			<div class="columns">
				<!-- Sport Dropdown -->
				<div class="column">
					<SelectSport></SelectSport>
				</div>

				<!-- Tournament Dropdown -->
				<div class="column">
					<SelectTournament></SelectTournament>
				</div>

				<!-- Twig Include -->
				<div class="column">
					<OutrightsIncludeSnippet></OutrightsIncludeSnippet>
				</div>
			</div>

			<template>
				<hr />

				<div class="columns">
					<!-- Upcoming Sport Events -->
					<div class="column is-8">
						<Outrights></Outrights>
					</div>

					<!-- Selected Sport Events -->
					<div class="column is-4">
						<SelectedOutrights></SelectedOutrights>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
// Components
import Outrights from "../components/outrights";
import SelectSport from "../components/select-sport";
import SelectTournament from "../components/select-tournament";
import SelectedOutrights from "../components/selected-outrights";

// Modules
import OutrightsIncludeSnippet from "../modules/outrights-include-snippet";

export default {
	data() {
		return {
			useCategories: false,
		};
	},

	components: {
		Outrights,
		SelectSport,
		SelectTournament,
		SelectedOutrights,
		OutrightsIncludeSnippet,
	},

	computed: {
		...mapGetters(["sportEvents", "selectedSport"]),
	},

	watch: {
	},
};
</script>
