<template>
	<div class="section">
		<div class="container">
			<div class="columns">
				<!-- Sport Dropdown -->
				<div class="column">
					<SelectSport></SelectSport>
				</div>

				<!-- Tournament Dropdown -->
				<div class="column">
					<SelectTournament></SelectTournament>
				</div>

				<!-- Twig Include -->
				<div class="column">
					<SportEventsIncludeSnippet></SportEventsIncludeSnippet>
				</div>
			</div>

			<template>
				<hr />

				<div class="columns">
					<!-- Upcoming Sport Events -->
					<div class="column is-8">
						<SportEvents></SportEvents>
					</div>

					<!-- Selected Sport Events -->
					<div class="column is-4">
						<SelectedSportEvents></SelectedSportEvents>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

// Components
import SportEvents from "../components/sport-events";
import SelectSport from "../components/select-sport";
import SelectTournament from "../components/select-tournament";

// Modules
import SportEventsIncludeSnippet from "../modules/include-snippet";
import SelectedSportEvents from "@/views/components/selected-sport-events.vue";

export default {
	data() {
		return {
			useCategories: false,
		};
	},

	components: {
    SelectedSportEvents,
		SportEvents,
		SelectSport,
		SelectTournament,
		SportEventsIncludeSnippet : SportEventsIncludeSnippet,
	},

	computed: {
		...mapGetters(["sportEvents", "selectedSport"]),
	},
};
</script>
