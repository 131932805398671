import { getTournamentsBySport } from "../../services/controllers/sport-events";

/**
 * -----------------------------------------------
 * STATE
 * -----------------------------------------------
 */
const defaultState = {
	/** The list of available tournaments. */
	tournaments: [],
	/** The selected tournament's id. */
	selectedTournament: ""
};

/**
 * -----------------------------------------------
 * ACTIONS
 * -----------------------------------------------
 */
const actions = {
	/** Call the "getTournamentsBySport" controller. */
	getTournaments: async (context) => {
		const sportsId = context.rootState.sports.selectedSport;
		try {
			context.commit("TOURNAMENTS_UPDATED", await getTournamentsBySport({ id: sportsId }));
		} catch (error) {
			console.error(error);
		}
	},
};

/**
 * -----------------------------------------------
 * MUTATIONS
 * -----------------------------------------------
 */
const mutations = {
	/**
	 * Update the "tournaments" state variable.
	 * @param {Object} state The Store State
	 * @param {Array<Object>} tournaments The list with the tournaments.
	 */
	TOURNAMENTS_UPDATED: (state, tournaments) => {
		state.tournaments = tournaments;
	},

	/**
	 * Update the "selectedTournament" state variable.
	 * @param {Object} state The Store State
	 * @param {String} sports The selected tournament's id.
	 */
	TOURNAMENT_SELECTED: (state, tournament) => {
		state.selectedTournament = tournament;
	},
};

/**
 * -----------------------------------------------
 * GETTERS
 * -----------------------------------------------
 */
const getters = {
	/**
	 * Get the list of available tournaments.
	 * @returns {Array<Object>} The list of available tournaments.
	 */
	tournaments: state => state.tournaments,
	/**
	 * Get the selected tournament's id.
	 * @returns {String} The selected tournament's id.
	 */
	selectedTournament: state => state.selectedTournament
};

export default {
	state: defaultState,
	getters,
	actions,
	mutations,
};
