import { getSports as getSportsController } from "../../services/controllers/sport-events";

/**
 * -----------------------------------------------
 * STATE
 * -----------------------------------------------
 */
const defaultState = {
	/** The list of the available sports. */
	sports: [],
	/** The selected sport. */
	selectedSport: ""
};

/**
 * -----------------------------------------------
 * ACTIONS
 * -----------------------------------------------
 */
const actions = {
	/** Call the "getSports" controller. */
	getSports: async (context) => {
		try {
			context.commit("SPORTS_UPDATED", await getSportsController(context.rootState.settings.oddsMarketType));
		} catch (error) {
			console.error(error);
		}
	},
};

/**
 * -----------------------------------------------
 * MUTATIONS
 * -----------------------------------------------
 */
const mutations = {
	/**
	 * Update the "sports" state variable.
	 * @param {Object} state The Store State
	 * @param {Array<Object>} sports The list with the sports.
	 */
	SPORTS_UPDATED: (state, sports) => {
		state.sports = sports;
	},

	/**
	 * Update the "selectedSport" state variable.
	 * @param {Object} state The Store State
	 * @param {String} sportId The id of the selected sport.
	 */
	SPORT_SELECTED: (state, sportId) => {
		state.selectedSport = sportId;
	},
};

/**
 * -----------------------------------------------
 * GETTERS
 * -----------------------------------------------
 */
const getters = {
	/**
	 * Get the list of available sports.
	 * @returns {Array<Object>} The list of available sports.
	 */
	sports: state => state.sports,
	/**
	 * Get the selected sport's id.
	 * @returns {String} The selected sport's id.
	 */
	selectedSport: state => state.selectedSport,
};

export default {
	state: defaultState,
	getters,
	actions,
	mutations,
};
