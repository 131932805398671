import _ from "lodash";

import {
	getOutrightsByTournament as getOutrightsByTournamentModel,
} from "../models/outrights";

/**
 * Controller for "getOutrightsByTournament" query.
 * @param {*} options 
 */
export function getOutrightsByTournament(options) {
	return getOutrightsByTournamentModel(options)
		.then(({ data }) => {
			data = data.data.filter(item => item.competitors.length > 0);

			data = data.map(item => {
				item.competitors = _.uniqBy(item.competitors, (i) => i.name);
				item.competitors = _.orderBy(item.competitors, ["name"], ["asc"]);

				return item;
			});

			return data;
		})
		.catch(console.error);
}
