/**
 * The output mode for the game selection and twig include's output.
 */
export const OUTPUT_MODE = Object.freeze({
	SINGLE_EVENT: "SINGLE_EVENT",
	SINGLE_MARKET: "SINGLE_MARKET",
	MULTIPLE_MARKETS: "MULTIPLE_MARKETS"
});

/**
 * The odds market type.
 */
export const ODDS_MARKET_TYPE = Object.freeze({
	EVENTS: "EVENTS",
	OUTRIGHTS: "OUTRIGHTS"
});

/**
 * The selected event's available actions.
 */
export const EVENT_ACTIONS = Object.freeze({
	MOVE_UP: "MOVE_UP",
	MOVE_DOWN: "MOVE_DOWN",
	REMOVE: "REMOVE"
});

/**
 * Sports list
 */
export const SPORTS = Object.freeze({
	BASKETBALL: "1103c71a18083d9e74b4a5c5d8d9a17d68d0",
	AMERICAN_FOOTBALL: "1103449a5f6d01d5f416810d04b4df596b6a",
	ICE_HOCKEY: "11036081becaf04f5a1455407d73e09bca13",
	BASEBALL: "1103ea94a7ff1f4ca3aac0fbc78682a7801e",
	GOLF: "1103690c3b1042564741e15a5ce503979172",
	BOXING: "31036512bd43d9caa6e02c990b0a82652dca",
	MOTORSPORT: "11033b7016975fda6e7c3533f876d6c41463",
	MMA: "11030374745b8aa244784ada619c9948181f",
	SOCCER: "1103871d17bd2733a95c351a87fd39f40bc1",
	TENNIS: "11030c493d20a11930e4980725d94b0203ec",
});

/**
 * Tournaments list
 */
export const TOURNAMENTS = Object.freeze({
	// American Football
	NFL: "110418f8abea98013844e3b2112c9d33ffb8",
	NCAAF: "1104c4505d6c12a453d0df18636902420151",
	XFL: "b9146f3558e7349efcb2f386942127119f90c1a9",
	// Baseball
	MLB: "1104d3e50c3b366f5d4a099904fb7d77b66c",
	// Basketball
	NBA: "110452b6d6fc82db6aef110362f4755a1fe8",
	NCAAB: "110412c817bc4f40d208d5421889680325b7",
	// Ice Hockey
	NHL: "11045702ddf5eff70b6a34baf150f53a5b6a",
	// Soccer
	PREMIER_LEAGUE: "110451c51148f67f99971e0e28838aa5880d",
	CHAMPIONS_LEAGUE: "1104fd9dfeda668ac9b0c5ef311ffdca8f71",
	MAJOR_LEAGUE_SOCCER: "110417bf9be4496b631d9e8bdd2e8850b528",
	WORLD_CUP: "1104804f6baa6cc7fa89dcbf175a8a38a9ab",
	// Tennis
	US_OPEN: "31042421fcb1263b9530df88f7f002e78ea5",
	// Motorsports
	NASCAR: "1104bd9da6708e7f9de9477b45df6e7585e3",
	// Golf
	US_OPEN_GOLF: "3104996a7fa078cc36c46d02f9af3bef918b",
	US_MASTERS: "310465658fde58ab3c2b6e5132a39fae7cb9",
});
