<template>
	<div class="box">
		<div class="title is-5">{{ data.name }}</div>

		<table class="table is-fullwidth is-hoverable">
			<tbody>
				<tr>
					<th>Sport Event Id:</th>
					<td class="has-text-centered">
						<div class="control">
							<input class="input" type="text" :value="data.event_id" readonly />
						</div>
					</td>
				</tr>
				<tr>
					<th>Home:</th>
					<td class="has-text-centered">
						<div class="control">
							<input class="input" type="text" :value="data.competitors.home.code" readonly />
						</div>
					</td>
				</tr>
				<tr>
					<th>Away:</th>
					<td class="has-text-centered">
						<div class="control">
							<input class="input" type="text" :value="data.competitors.away.code" readonly />
						</div>
					</td>
				</tr>
				<tr>
					<th>Sport Event Date:</th>
					<td class="has-text-centered">
						<div class="control">
							<input class="input" type="text" :value="data.scheduled_at | moment('YYYY-MM-DD HH:mm:ss')" readonly />
						</div>
					</td>
				</tr>
				<tr>
					<th>UTC Date:</th>
					<td class="has-text-centered">
						<div class="control">
							<span style="color: #ccc">{{ data.scheduled_at }}</span>
						</div>
					</td>
				</tr>
			</tbody>
		</table>

		<div class="market block">
			<div class="heading has-text-weight-bold">Select Market</div>
			<div class="select is-fullwidth">
				<select v-model="selectedMarket" :disabled="data.marketsList.length === 0">
					<option hidden disabled selected>Select Market</option>
					<option v-for="(market, index) of data.marketsList" :key="index" :value="market">
						{{ prettifiedMarket(market) }}
					</option>
				</select>
			</div>
		</div>

		<div class="outcome block">
			<div class="heading has-text-weight-bold">Select Outcome</div>
			<div class="select is-fullwidth">
				<select :disabled="filteredOutcomes.length === 0" v-model="selectedOutcome">
					<option hidden disabled selected>Select Outcome</option>
					<option v-for="(outcome, index) of filteredOutcomes" :key="index" :value="outcome">
						{{ outcome }}
					</option>
				</select>
			</div>
		</div>
		<button class="button is-fullwidth is-link" @click="addToList(data.id, data.name, selectedMarket, selectedOutcome, data.scheduled)" :disabled="selectedMarket === ('Select Market' || null) || selectedOutcome === ('Select Outcome' || null)">Add to List</button>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

import { competitorsOrder } from "../../config";

export default {
	data: () => ({
		selectedMarket: "Select Market",
		selectedOutcome: "Select Outcome",
		filteredOutcomes: [],
	}),

	props: {
		data: Object,
		addToList: Function,
	},

	computed: {
		...mapGetters(["tournaments", "selectedTournament"]),
	},

	methods: {
		/**
		 * Convert twoway and threeway to moneyline and winner.
		 */
		prettifiedMarket(marketName) {
			switch (marketName) {
				case "twoway":
					return "moneyline";
				case "threeway":
					return "winner";
				default:
					return marketName;
			}
		},
		/**
		 * Parse competitor data to fields required
		 *
		 */
		competitorsData(competitors) {
			let competitorsData = {};

			// Create competitors Object - fixed when new query implemented
			competitorsOrder(this.selectedTournament.name).map((qualifier) => {
				competitorsData[qualifier] = {
					qualifier: competitors[qualifier].qualifier,
					abbreviation: competitors[qualifier].abbreviation,
					name: competitors[qualifier].competitor.name,
					competitorName: competitors[qualifier].competitorName,
				};
			});

			return competitorsData;
		},
	},

	watch: {
		selectedMarket: function () {
			this.selectedOutcome = "Select Outcome";
			this.filteredOutcomes = this.data.outcomeTypes[this.selectedMarket];
		},
	},
};
</script>
