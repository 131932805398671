<template>
	<div class="box">
		<div class="title is-5">{{ data.name }}</div>

		<div class="columns">
			<div class="column">
				<b>Competitor:</b>
				{{ data.competitorName }}
			</div>
		</div>

		<div class="buttons is-centered">
			<button
				class="button is-primary"
				@click="
					MODIFY_OUTRIGHT({
						id: data.id,
						action: EVENT_ACTIONS.MOVE_UP,
					})
				"
				:disabled="isFirstItem"
			>
				Move Up
			</button>

			<button
				class="button is-info"
				@click="
					MODIFY_OUTRIGHT({
						id: data.id,
						action: EVENT_ACTIONS.MOVE_DOWN,
					})
				"
				:disabled="isLastItem"
			>
				Move Down
			</button>

			<button
				class="button is-danger"
				@click="
					MODIFY_OUTRIGHT({
						id: data.id,
						action: EVENT_ACTIONS.REMOVE,
					})
				"
			>
				Remove
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import { EVENT_ACTIONS } from "../../utils/enums";

export default {
	data: () => ({
		EVENT_ACTIONS,
	}),

	props: {
		data: Object,
	},

	computed: {
		...mapGetters(["selectedOutrights"]),

		isFirstItem() {
			return this.selectedOutrights.findIndex((i) => i.id === this.data.id) === 0 ? true : false;
		},

		isLastItem() {
			return this.selectedOutrights.findIndex((i) => i.id === this.data.id) === this.selectedOutrights.length - 1 ? true : false;
		},
	},

	methods: {
		...mapMutations(["MODIFY_OUTRIGHT"]),
	},
};
</script>
