var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"title is-5"},[_vm._v(_vm._s(_vm.data.name))]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b',[_vm._v("Competitor:")]),_vm._v(" "+_vm._s(_vm.data.competitorName)+" ")])]),_c('div',{staticClass:"buttons is-centered"},[_c('button',{staticClass:"button is-primary",attrs:{"disabled":_vm.isFirstItem},on:{"click":function($event){return _vm.MODIFY_OUTRIGHT({
					id: _vm.data.id,
					action: _vm.EVENT_ACTIONS.MOVE_UP,
				})}}},[_vm._v(" Move Up ")]),_c('button',{staticClass:"button is-info",attrs:{"disabled":_vm.isLastItem},on:{"click":function($event){return _vm.MODIFY_OUTRIGHT({
					id: _vm.data.id,
					action: _vm.EVENT_ACTIONS.MOVE_DOWN,
				})}}},[_vm._v(" Move Down ")]),_c('button',{staticClass:"button is-danger",on:{"click":function($event){return _vm.MODIFY_OUTRIGHT({
					id: _vm.data.id,
					action: _vm.EVENT_ACTIONS.REMOVE,
				})}}},[_vm._v(" Remove ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }