<template>
	<div class="box">
		<div class="title is-5">{{ data.name }}</div>

		<table class="table is-fullwidth is-hoverable">
			<tbody>
				<tr>
					<th>Id:</th>
					<td class="has-text-centered">
						<div class="control">
							<input class="input" type="text" :value="data.future_id" readonly />
						</div>
					</td>
				</tr>
				<tr>
					<th>Competitor Id:</th>
					<td class="has-text-centered">
						<div class="control">
							<input class="input" type="text" :value="selectedCompetitorId" readonly />
						</div>
					</td>
				</tr>
			</tbody>
		</table>

		<div class="heading has-text-weight-bold">Select Competitor</div>
		<div class="block select is-fullwidth">
			<select v-model="selectedCompetitorId">
				<option hidden disabled selected>Select Competitor</option>
				<option :key="index" v-for="(comp, index) of data.competitors" :value="comp.matrix_id">{{ comp.name }}</option>
			</select>
		</div>

		<button class="button is-fullwidth is-link" @click="addToList(data.future_id, data.name, getCompetitorAbbreviation(selectedCompetitorId), selectedCompetitorId, getCompetitorName(selectedCompetitorId))" :disabled="selectedCompetitorId === ('Select Competitor' || null)">Add to List</button>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	data: () => ({
		selectedCompetitorId: "Select Competitor",
	}),

	props: {
		data: Object,
		addToList: Function,
	},

	computed: {
		...mapGetters(["tournaments", "selectedTournament"]),
	},

	methods: {
		getCompetitorName(id) {
			return this.data.competitors.find((i) => i.matrix_id === id).name;
		},

		getCompetitorAbbreviation(id) {
			return this.data.competitors.find((i) => i.matrix_id === id).code;
		},
	},
};
</script>
