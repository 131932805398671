import Vue from "vue";
import Vuex from "vuex";

// Modules
import sportsModule from "./modules/sports";
import tournamentsModule from "./modules/tournaments";
import sportEventsModule from "./modules/sport-events";
import outrightsModule from "./modules/outrights";
import settingsModule from "./modules/settings";


Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		sports: sportsModule,
		tournaments: tournamentsModule,
		sportEvents: sportEventsModule,
		outrights: outrightsModule,
		settings: settingsModule
	},
});
