import axios from "axios";
import { MATRIX_CORE_CONFIG } from "@/config";

/**
 * API call for "getOutrightsByTournament" query.
 * @param {Object} options 
 */
export function getOutrightsByTournament(options) {
	return axios.get(MATRIX_CORE_CONFIG.endpoint + "/api/direct/futures-with-odds", {
		withCredentials: true,
		params: {
			league_ids: options.leagueId,
			books_limit: 1,
			per_page: options.limit
		},
		headers: {
			"Accept": "application/json",
		}
	});
}