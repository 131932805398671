<template>
	<div>
		<div class="heading has-text-weight-bold">Upcoming Sport Events</div>
		<div class="columns is-multiline">
			<div v-for="(event, index) of sportEvents" :key="index" class="column is-6">
				<Event :data="event" :addToList="addToList"></Event>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import Event from "../modules/event";
import { OUTPUT_MODE } from "../../utils/enums";
// import Notification from "../modules/notification";

export default {
	components: {
		Event,
		// Notification
	},

	computed: {
		...mapGetters(["sportEvents", "selectedTournament", "selectedSportEvents", "outputMode"]),
	},

	methods: {
		...mapMutations(["SPORT_EVENT_SELECTED", "SPORT_EVENTS_UPDATED", "CLEAR_SELECTED_EVENTS"]),

		/**
		 * Add an item to the selected events list.
		 *
		 * @param {String} id The sportEventId
		 * @param {String} name The sport event name.
		 * @param {String} market The selected market.
		 * @param {String} outcome The selected outcome.
		 * @param {String} home The home team's abbreviation.
		 * @param {String} away The away team's abbreviation.
		 */
		addToList(id, name, market, outcome, scheduled) {
			switch (this.outputMode) {
				case OUTPUT_MODE.SINGLE_EVENT:
					if (this.selectedSportEvents.length === 0) {
						this.SPORT_EVENT_SELECTED({
							id,
							name,
							market,
							outcome,
							scheduled,
						});
					}
					break;

				case OUTPUT_MODE.MULTIPLE_MARKETS:
					this.SPORT_EVENT_SELECTED({
						id,
						name,
						market,
						outcome,
						hasMultipleMarkets: true,
						scheduled,
					});
					break;

				// SINGLE_MARKET
				default:
					if (!this.selectedSportEvents.find((i) => i.id === id && i.market === market)) {
						this.SPORT_EVENT_SELECTED({
							id,
							name,
							market,
							outcome,
							scheduled,
						});
					}
					break;
			}
		},
	},

	watch: {
		selectedTournament: function () {
			this.SPORT_EVENTS_UPDATED([]);
			this.CLEAR_SELECTED_EVENTS();
			this.$store.dispatch("getSportEvents");
		},
	},
};
</script>
