import axios from "axios";
import { MATRIX_CORE_CONFIG } from "../../config";

/**
 * API call for "getSports" query.
 * @param {Object} options
 */
export function getSports() {
	// eslint-disable-next-line no-console
	return axios.get(MATRIX_CORE_CONFIG.endpoint + "/api/direct/sports",{
		withCredentials: true,
		headers: {
			"Accept": "application/json",
		},
		maxRedirects: 5
	});
}

/**
 * API call for "getTournamentsBySport" query.z
 * @param {Object} options
 */
export function getTournamentsBySport(options) {
	return axios.get( MATRIX_CORE_CONFIG.endpoint + "/api/direct/leagues", {
		withCredentials: true,
		params: {
			sport_id: options.id
		},
		headers: {
			"Accept": "application/json"
		},
		maxRedirects: 5
	});
}

/**
 * API call for "getSportEventsByTournament" query.
 * @param {Object} options 
 */
export function getSportEventsByTournament(options) {
	return axios.get(MATRIX_CORE_CONFIG.endpoint + "/api/direct/events-with-odds", {
		withCredentials: true,
		params: {
			league_ids: options.leagueId,
			scheduled_at_min: options.scheduledStart,
			scheduled_at_max: options.scheduledEnd,
			per_page: options.limit
		},
		headers: {
			"Accept": "application/json",
		},
		maxRedirects: 5,
	});
}
