<template>
	<div class="section has-background-white-ter" style="height: 100%">
		<div class="menu">
			<p class="menu-label">Settings</p>
			<ul class="menu-list">
				<li class="block">
					<p class="heading">Odds Market Type</p>
					<div class="select is-fullwidth">
						<select v-model="marketType">
							<option :value="ODDS_MARKET_TYPE.EVENTS">Events</option>
							<option :value="ODDS_MARKET_TYPE.OUTRIGHTS">Futures</option>
						</select>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapMutations } from "vuex";

import { OUTPUT_MODE, ODDS_MARKET_TYPE } from "../../utils/enums";

export default {
	data: () => ({
		OUTPUT_MODE,
		ODDS_MARKET_TYPE,
		outputMode: OUTPUT_MODE.SINGLE_MARKET,
		marketType: ODDS_MARKET_TYPE.EVENTS,
	}),

	methods: {
		...mapMutations(["SPORT_SELECTED", "TOURNAMENT_SELECTED", "OUTPUT_MODE_UPDATED", "CLEAR_SELECTED_EVENTS", "ODDS_MARKET_TYPE_UPDATED", "SPORTS_UPDATED", "TOURNAMENTS_UPDATED", "CLEAR_SELECTED_OUTRIGHTS", "SPORT_EVENTS_UPDATED", "OUTRIGHTS_UPDATED"]),
	},

	watch: {
		outputMode: function () {
			this.CLEAR_SELECTED_EVENTS();
			this.CLEAR_SELECTED_OUTRIGHTS();
			this.OUTPUT_MODE_UPDATED(this.outputMode);
		},
		marketType: function () {
			this.SPORTS_UPDATED([]);
			this.TOURNAMENTS_UPDATED([]);

			this.CLEAR_SELECTED_EVENTS();
			this.CLEAR_SELECTED_OUTRIGHTS();

			this.SPORT_SELECTED("");
			this.TOURNAMENT_SELECTED("");

			this.SPORT_EVENTS_UPDATED([]);
			this.OUTRIGHTS_UPDATED([]);

			this.ODDS_MARKET_TYPE_UPDATED(this.marketType);
		},
	},
};
</script>
