<template>
	<div style="position: sticky; top: 0">
		<div class="columns">
			<div class="column">
				<div class="heading has-text-weight-bold">
					Selected Sport Events
				</div>
			</div>
			<div class="column is-narrow">
				<button
					class="button"
					:disabled="selectedSportEvents.length === 0"
					@click="CLEAR_SELECTED_EVENTS"
				>
					Clear All
				</button>
			</div>
		</div>
		<div class="columns is-multiline">
			<div
				v-for="(event, index) of this.selectedSportEvents"
				:key="index"
				class="column is-12"
			>
				<SelectedEvent :data="event"></SelectedEvent>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import SelectedEvent from "../modules/selected-event";

export default {
	components: {
		SelectedEvent,
	},

	computed: {
		...mapGetters(["selectedSportEvents"]),
	},

	methods: {
		...mapMutations(["SPORT_EVENTS_UPDATED", "CLEAR_SELECTED_EVENTS"]),

		clearAll() {
			this.CLEAR_SELECTED_EVENTS();
		},
	},
};
</script>
