<template>
	<div>
		<div class="heading has-text-weight-bold">Upcoming Outrights</div>
		<div class="columns is-multiline">
			<div v-for="(event, index) of outrights" :key="index" class="column is-6">
				<Outright :data="event" :addToList="addToList"></Outright>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import Outright from "../modules/outright";
// import Notification from "../modules/notification";

export default {
	components: {
		Outright,
		// Notification
	},

	computed: {
		...mapGetters(["outrights", "selectedTournament", "selectedOutrights"]),
	},

	methods: {
		...mapMutations(["OUTRIGHT_SELECTED", "OUTRIGHTS_UPDATED", "CLEAR_SELECTED_OUTRIGHTS"]),

		/**
		 * Add an item to the selected events list.
		 *
		 * @param {String} id The sportEventId
		 * @param {String} name The sport event name.
		 * @param {String} competitor The selected competitor.
		 */
		addToList(id, name, abbreviation, competitorId, competitorName, endDate) {
			if (!this.selectedOutrights.find((i) => i.id === id && i.competitor.id === competitorId)) {
				this.OUTRIGHT_SELECTED({
					id,
					name,
					abbreviation,
					competitorId,
					competitorName,
					endDate,
				});
			}
		},
	},

	watch: {
		selectedTournament: function () {
			this.OUTRIGHTS_UPDATED([]);
			this.CLEAR_SELECTED_OUTRIGHTS();
			this.$store.dispatch("getOutrights");
		},
	},
};
</script>
