<template>
	<div class="sport">
		<div class="heading has-text-weight-bold">Select Sport</div>
		<div :class="['select', 'is-fullwidth', sports.length === 0 ? 'is-loading' : null]">
			<select :disabled="sports.length === 0" v-model="selectedSport">
				<option hidden disabled selected>Select Sport</option>
				<option v-for="(sport, index) of sports" :key="index" :value="sport.id">
					{{ sport.name }}
				</option>
			</select>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
	data: () => ({
		selectedSport: "Select Sport",
	}),

	created() {
    // eslint-disable-next-line no-console
		this.$store.dispatch("getSports");
	},

	computed: {
		...mapGetters(["sports"]),
	},

	methods: {
		...mapMutations(["SPORT_SELECTED", "SPORT_EVENTS_UPDATED", "TOURNAMENTS_UPDATED"]),
	},

	watch: {
		selectedSport: function () {
			this.TOURNAMENTS_UPDATED([]);
			this.SPORT_EVENTS_UPDATED([]);
			this.SPORT_SELECTED(this.selectedSport);
		},
	},
};
</script>
