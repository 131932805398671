<template>
	<div id="app">
		<div class="columns">
			<div class="column is-2 is-paddingless" style="position: fixed; top: 0; left: 0; bottom: 0">
				<Sidebar></Sidebar>
			</div>
			<div class="column is-offset-2">
				<Odds v-if="oddsMarketType === ODDS_MARKET_TYPE.EVENTS"></Odds>
				<Outrights v-else-if="oddsMarketType === ODDS_MARKET_TYPE.OUTRIGHTS"></Outrights>
				<div v-else class="notification is-danger">Something went wrong!</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

import { ODDS_MARKET_TYPE } from "../utils/enums";

import Sidebar from "./includes/sidebar";
import Odds from "./includes/odds";
import Outrights from "./includes/outrights";

export default {
	data: () => ({
		ODDS_MARKET_TYPE,
	}),
	components: {
		Sidebar,
		Odds,
		Outrights,
	},
	computed: {
		...mapGetters(["oddsMarketType"]),
	},
};
</script>
