<template>
	<div class="tournament">
		<div class="heading has-text-weight-bold">Select Tournament</div>
		<div :class="['select', 'is-fullwidth', tournaments.length === 0 ? 'is-loading' : null]">
			<select :disabled="tournaments.length === 0" v-model="selectedTournament">
				<option hidden disabled selected>Select Tournament</option>
				<option v-for="(tournament, index) of tournaments" :key="index" :value="{ id: tournament.id, name: tournament.name }">
					{{ tournament.name }}
				</option>
			</select>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
	data: () => ({
		selectedTournament: "Select Tournament",
	}),

	computed: {
		...mapGetters(["tournaments", "selectedSport"]),
	},

	methods: {
		...mapMutations(["TOURNAMENT_SELECTED", "SPORT_EVENTS_UPDATED", "CLEAR_SELECTED_EVENTS"]),
	},

	watch: {
		selectedTournament: function () {
			this.TOURNAMENT_SELECTED(this.selectedTournament);
		},

		selectedSport: function () {
			this.selectedTournament = "Select Tournament";
			this.SPORT_EVENTS_UPDATED([]);
			this.CLEAR_SELECTED_EVENTS();
			this.$store.dispatch("getTournaments");
		},
	},
};
</script>
