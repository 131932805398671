<template>
	<div class="include-snippet">
		<div class="heading has-text-weight-bold">Blade Include</div>
		<div class="control">
			<textarea class="textarea has-fixed-size" readonly :disabled="selectedSportEvents.length === 0" v-model="generateInclude" style="cursor: initial"></textarea>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters(["selectedSportEvents"]),

		generateInclude() {
			return `<livewire:matrix.picks-table :sport-events="${JSON.stringify(this.selectedSportEvents, null, "\t").replace(/'/g, "\\'").replace(/"/gm, "'").replace(/{/gm, "[").replace(/}/gm, "]").replace(/:\s/gm, " => ")}" />`;
		},
	},
};
</script>
