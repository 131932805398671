import moment from "moment-timezone";

import { getOutrightsByTournament } from "../../services/controllers/outrights";
import { EVENT_ACTIONS } from "../../utils/enums";

/**
 * -----------------------------------------------
 * STATE
 * -----------------------------------------------
 */
const defaultState = {
	/** The available sport events for the selected tournament. */
	outrights: [],
	/** The selected sport events. */
	selectedOutrights: []
};

/**
 * -----------------------------------------------
 * ACTIONS
 * -----------------------------------------------
 */
const actions = {
	/** Call the "getOutrightsByTournament" controller. */
	getOutrights: async (context) => {
		const options = {
			endDate: moment(new Date()).utc().format(),
			limit: 50
		};

		try {
			options.leagueId = context.rootState.tournaments.selectedTournament.id;

			context.commit("OUTRIGHTS_UPDATED", await getOutrightsByTournament(options));
		} catch (error) {
			console.error(error);
		}
	},
};

/**
 * -----------------------------------------------
 * MUTATIONS
 * -----------------------------------------------
 */
const mutations = {
	/**
	 * Update the "outrights" state variable.
	 * @param {Object} state The Store State
	 * @param {Array<Object>} outrights the list with the sport events.
	 */
	OUTRIGHTS_UPDATED: (state, outrights) => {
		state.outrights = outrights;
	},

	/**
	 * Update the "selectedOutrights" state variable.
	 * @param {Object} state The Store State
	 * @param {Object} outrights the selected item to be added into the list.
	 */
	OUTRIGHT_SELECTED: (state, outright) => {
		state.selectedOutrights.push(outright);
	},

	/**
	 * Clear the "selectedOutrights" array.
	 * @param {Object} state The Store State
	 */
	CLEAR_SELECTED_OUTRIGHTS: (state) => {
		state.selectedOutrights = [];
	},

	/**
	 * Remove/Move Up/Move Down each selected item in the "selectedOutrights" variable.
	 * @param {Object} state The Store State
	 * @param {Object} options the item's id and action type.
	 */
	MODIFY_OUTRIGHT: (state, options) => {
		let itemIndex = state.selectedOutrights.findIndex(i => i.id === options.id);
		let selectedOutrightsList = state.selectedOutrights;

		switch (options.action) {
			case EVENT_ACTIONS.MOVE_UP:
				if (itemIndex > 0) {
					const el = selectedOutrightsList[itemIndex];
					selectedOutrightsList[itemIndex] = selectedOutrightsList[itemIndex - 1];
					selectedOutrightsList[itemIndex - 1] = el;
					state.selectedOutrights = [...selectedOutrightsList];
				}
				break;
			case EVENT_ACTIONS.MOVE_DOWN:
				if (itemIndex !== -1 && itemIndex < selectedOutrightsList.length - 1) {
					const el = selectedOutrightsList[itemIndex];
					selectedOutrightsList[itemIndex] = selectedOutrightsList[itemIndex + 1];
					selectedOutrightsList[itemIndex + 1] = el;
					state.selectedOutrights = [...selectedOutrightsList];
				}
				break;
			case EVENT_ACTIONS.REMOVE:
				state.selectedOutrights.splice(itemIndex, 1);
				break;
		}
	},
};

/**
 * -----------------------------------------------
 * GETTERS
 * -----------------------------------------------
 */
const getters = {
	/**
	 * Get the "outrights" state variable.
	 * @returns {Array<Object>} the available sport events.
	 */
	outrights: state => state.outrights,
	/**
	 * Get the "selectedOutrights" state variable.
	 * @returns {Array<Object>} the selected sport events.
	 */
	selectedOutrights: state => state.selectedOutrights
};

export default {
	state: defaultState,
	getters,
	actions,
	mutations,
};
