<template>
	<div style="position: sticky; top: 0">
		<div class="columns">
			<div class="column">
				<div class="heading has-text-weight-bold">Selected Outrights</div>
			</div>
			<div class="column is-narrow">
				<button class="button" :disabled="selectedOutrights.length === 0" @click="CLEAR_SELECTED_OUTRIGHTS">Clear All</button>
			</div>
		</div>
		<div class="columns is-multiline">
			<div v-for="(event, index) of this.selectedOutrights" :key="index" class="column is-12">
				<SelectedOutright :data="event"></SelectedOutright>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import SelectedOutright from "../modules/selected-outright";

export default {
	components: {
		SelectedOutright,
	},

	computed: {
		...mapGetters(["selectedOutrights"]),
	},

	methods: {
		...mapMutations(["CLEAR_SELECTED_OUTRIGHTS"]),

		clearAll() {
			this.CLEAR_SELECTED_OUTRIGHTS();
		},
	},
};
</script>
