// Libraries
import Vue from "vue";
import Vuex from "vuex";
import VueMoment from "vue-moment";
import moment from "moment-timezone";

// Components
import App from "./views/App";
import store from "./store";

Vue.config.productionTip = false;

// Vuex Assignment.
Vue.use(Vuex);

Vue.use(VueMoment, {
    moment,
});

// Render Component.
new Vue({
	store,
	render: h => h(App),
}).$mount("#app");
